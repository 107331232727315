import React, { Component } from 'react';

class Teamitem extends Component {
  render() {
    const {
      teamImage,
      memberN,
      memberd,
      linkedinLink,
    } = this.props;

    const profileLink = '#';

    return (
      <div className="ex_team_item">
        <img src={require(`../../img/teams/${teamImage}`)} alt="team" />
        <div className="team_content">
          <a href={profileLink}>
            <h3 className="f_p f_size_16 f_600 t_color3">{memberN}</h3>
          </a>
          <h5>{memberd}</h5>
        </div>
        <div className="hover_content">
          <div className="n_hover_content">
            { linkedinLink !== undefined
                            && (
                            <ul className="list-unstyled">
                              <li>
                                <a href={linkedinLink}><i className="ti-linkedin" /></a>
                              </li>
                            </ul>
                            )}
            <div className="br" />
            <a href={profileLink}>
              <h3 className="f_p f_size_16 f_600 w_color">{memberN}</h3>
            </a>
            <h5>{memberd}</h5>
          </div>
        </div>
      </div>
    );
  }
}
export default Teamitem;
