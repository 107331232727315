import React from 'react';
import Reveal from 'react-reveal';
import { Link } from 'gatsby';
import Sectitle from '../Title/Sectitle';
import Teamitem from './Teamitem';
import Noop from '../../util/Noop';


const TeamGridItem = Noop;

const TeamGrid = ({
  title,
  subtitle,
  cta,
  ctaUrl,
  children,
}) => (
  <section className="experts_team_area sec_pad" id="team">
    <div className="container">
      <Sectitle
        sClass="sec_title text-center mb_70"
        Title={title}
        tClass="t_color3"
        TitleP={subtitle}
      />
      <Reveal bottom cascade>
        <div className="row justify-content-md-center">
          {
                            React.Children.map(children, (child, index) => {
                              const {
                                image,
                                name,
                                description,
                                linkedinLink,
                              } = child.props;

                              return (
                                <div className="col-lg-3 col-sm-6">
                                  <Teamitem
                                    teamImage={image}
                                    memberN={name}
                                    memberd={description}
                                    linkedinLink={linkedinLink}
                                  />
                                </div>
                              );
                            })
                        }
          {
                          cta !== undefined && ctaUrl !== undefined
                          && (
                          <div className="col-lg-12 text-center">
                            <Link to={ctaUrl} className="learn_btn">
                              {cta}
                              {' '}
                              <i className="ti-arrow-right" />
                            </Link>
                          </div>
                          )
                        }
        </div>
      </Reveal>
    </div>
  </section>

);

TeamGrid.Item = TeamGridItem;

export default TeamGrid;
