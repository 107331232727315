import React from 'react';
import TeamGrid from '../../components/TeamGrid/TeamGrid';
import urls from '../../data/urls';

const Team = () => (
  <div>
    <TeamGrid
      title="The Founders"
      subtitle="Your main points of contact"
      cta="Let's get in touch"
      ctaUrl={urls.contactUs}
    >
      <TeamGrid.Item
        image="josh-wentworth.jpg"
        name="Josh Wentworth"
        description="Co-founder"
        linkedinLink="https://www.linkedin.com/in/joshwentworth/"
      />
      <TeamGrid.Item
        image="thomas-johnston.jpg"
        name="Thomas Johnston"
        description="Co-founder"
        linkedinLink="https://www.linkedin.com/in/trjohnst/"
      />
    </TeamGrid>
    <TeamGrid
      title="The Team"
      subtitle="Talented professionals we partner with regularly"
    >
      <TeamGrid.Item
        image="ben-fagin.jpg"
        name="Ben Fagin"
        description="Technical Architect"
      />
      <TeamGrid.Item
        image="lucas-noah.jpg"
        name="Lucas Noah"
        description="Backend/DevOps Engineer"
      />
      <TeamGrid.Item
        image="brian-fogg.jpg"
        name="Brian Fogg"
        description="Frontend/Mobile Engineer"
      />
      <TeamGrid.Item
        image="kat-ramirez.jpg"
        name="Kat Ramirez"
        description="Visual Designer"
      />
    </TeamGrid>
  </div>
);
export default Team;
